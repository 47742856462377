@font-face {
    font-family: "TTCommons";
    src: url("https://swissborg.com/fonts/TTCommons-DemiBold.woff2");
    font-weight: bold;
  }

  @font-face {
    font-family: "TTCommons";
    src: url("https://swissborg.com/fonts/TTCommons-Regular.woff2");
    font-weight: regular;
  }